import fetch from 'auth/FetchInterceptor'

const questionService = {}
// createquestion
questionService.createQuestion = function (data) {
    return fetch({
        url: '/questionStore/',
        method: 'post',
        data
    })
}

questionService.addBulkQuestion = function (data, params) {
    return fetch({
        url: `/questionStore/csv?stageId=${params.stageId}`,
        method: 'post',
        data
    })
}

questionService.getQuestions = function (params) {
    return fetch({
        url: '/questionStore/',
        method: 'get',
        params
    })
}

questionService.getaQuestion = function (params) {
    return fetch({
        url: `/questionStore/${params.questionId}`,
        method: 'get',
        params
    })
}

questionService.updateQuestion = function (data, params) {
    return fetch({
        url: `/questionStore/${params.id}`,
        method: 'put',
        data
    })
}

questionService.deleteQuestion = function (params) {
    return fetch({
        url: `/questionStore/${params.questionId}`,
        method: 'delete',
    })
}



export default questionService;