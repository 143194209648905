import { createAsyncThunk } from '@reduxjs/toolkit';
import roleBuilderService from 'services/roleBuilderService';
import { createSlice } from '@reduxjs/toolkit';

export const fetchRoleBuilderData = createAsyncThunk('roleBuilder/fetchRoleBuilderData', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.getAllJobRoles(data)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const downloadRolePDF = createAsyncThunk('roleBuilder/downloadRolePDF', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.downloadRolePDF(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})




export const fetchRoleBuilderDataByParams = createAsyncThunk('roleBuilder/fetchRoleBuilderDataByParams', async (params, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.fetchRoleBuilderDataByParams(params)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getJobRoleById = createAsyncThunk('roleBuilder/getJobRoleById', async (params, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.getJobRoleById(params)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})



// cloneRoleBuilderData
export const cloneRoleBuilderData = createAsyncThunk('roleBuilder/cloneRoleBuilderData', async (params, { rejectWithValue }) => {
	try {
		
		const response = await roleBuilderService.cloneRoleBuilderData(params)
		const responseData = response.data;
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteRoleBuilder = createAsyncThunk('roleBuilder/deleteRoleBuilder', async (params, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.deleteRoleBuilder(params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const refreshAsesment = createAsyncThunk('roleBuilder/refreshAsesment', async (params, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.refreshAsesment(params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createRoleBuilderData = createAsyncThunk('roleBuilder/createRoleBuilderData', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.createRoleBuilderData(data)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const updateRoleBuilderData = createAsyncThunk('roleBuilder/updateRoleBuilderData', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.updateRoleBuilderData(data)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const fetchFiles = createAsyncThunk('roleBuilder/fetchFiles', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.getRoleBuilderFile(data)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const saveFile = createAsyncThunk('roleBuilder/saveFile', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.createRoleBuilderFile(data, params)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getRoleBuilderFiles = createAsyncThunk('roleBuilder/getRoleBuilderFiles', async (params, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.getRoleBuilderFiles(params)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteRoleBuilderFile = createAsyncThunk('roleBuilder/deleteRoleBuilderFile', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.deleteRoleBuilderFile(data)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const updateRoleBuilderFile = createAsyncThunk('roleBuilder/updateRoleBuilderFile', async (data, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.updateRoleBuilderFile(data)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const addCodesForConsideration = createAsyncThunk('roleBuilder/updateRoleBuilderFile', async ({data, params}, { rejectWithValue }) => {
	try {
		const response = await roleBuilderService.addCodesForConsideration(data, params)
		const responseData = response.data
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

const roleBuilderSlice = createSlice({
	name: 'roleBuilder',
	initialState: {
		roleBuilderData: null,
		loading: false,
		error: null,
		files: [],
		filteredFiles: [],
		searchQuery: '',
		loading: false,
		error: null,
	},
	reducers: {
		resetRoleBuilderData: (state) => {
			state.roleBuilderData = null;
		},
		toggleEmployeeView: (state, action) => {
			const file = state.files.find((file) => file.id === action.payload);
			file.employee = !file.employee;
		},
		removeFile: (state, action) => {
			state.files = state.files.filter((file) => file.id !== action.payload);
		},
		resetState: (state) => {
			state.files = [];
			state.loading = false;
			state.error = null;
		},
		setFiles: (state, action) => {
			state.files = action.payload;
			state.filteredFiles = action.payload;
		},
		setSearchQuery: (state, action) =>  {
			state.searchQuery = action.payload;
			state.filteredFiles = state.files.filter(file =>
			  file.get('fileName').toLowerCase().includes(state.searchQuery.toLowerCase()) ||
			  file.get('type').toLowerCase().includes(state.searchQuery.toLowerCase())
			);
		  }
	},
	extraReducers: {
		[fetchRoleBuilderData.pending]: (state) => {
			state.loading = true;
		},
		[fetchRoleBuilderData.fulfilled]: (state, action) => {
			state.roleBuilderData = action.payload;
			state.loading = false;
		},
		[fetchRoleBuilderData.rejected]: (state, action) => {
			state.error = action.error;
			state.loading = false;
		},	
		[fetchRoleBuilderDataByParams.pending]: (state) => {
			state.loading = true;
		},
		[fetchRoleBuilderDataByParams.fulfilled]: (state, action) => {
			state.roleBuilderData = action.payload;
			state.loading = false;
		},
		[fetchRoleBuilderDataByParams.rejected]: (state, action) => {
			state.error = action.error;
			state.loading = false;
		},

		[fetchFiles.pending]: (state) => {
			state.loading = true;
		},
		[fetchFiles.fulfilled]: (state, action) => {
			state.files = action.payload;
			state.loading = false;
		},
		[fetchFiles.rejected]: (state, action) => {
			state.error = action.error.message;
			state.loading = false;
		},

		[saveFile.pending]: (state) => {
			state.loading = true;
		},
		[saveFile.fulfilled]: (state, action) => {
			state.files.push(action.payload);
			state.loading = false;
		},
		[saveFile.rejected]: (state, action) => {
			state.error = action.error.message;
			state.loading = false;
		}, 
		[deleteRoleBuilderFile.pending]: (state) => {
			state.loading = true;
		},
		[deleteRoleBuilderFile.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[deleteRoleBuilderFile.rejected]: (state, action) => {
			state.error = action.error.message;
			state.loading = false;
		},

	}
});
export const { toggleEmployeeView, removeFile, resetState, setFiles, setSearchQuery } = roleBuilderSlice.actions

export default roleBuilderSlice.reducer
