import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import {
	AUTH_PREFIX_PATH,
	UNAUTHENTICATED_ENTRY,
	REDIRECT_URL_KEY
} from 'configs/AppConfig'
import navigationConfig from 'configs/NavigationConfig';
import Localization from 'views/app-views/docs/components/Localization';

const ProtectedRoute = () => {

	const { token } = useSelector(state => state.auth)
	const location = useLocation()


	if (!token) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	} else {
		let tokenData = jwt_decode(token);
		let superAdminNav = navigationConfig[0].submenu
		let employeeNav  =navigationConfig[1].submenu

		let isInSUperadmin = superAdminNav.findIndex((e) => e.path === location.pathname)
		let sleNavIndex = employeeNav.findIndex((e) => {
			if(location.pathname.indexOf(e.path) > -1){
				return true
			}
			return false
		})
			
		if(tokenData.userRole === "SUPER_ADMIN"){ 

			if (sleNavIndex > -1 && location.pathname !== "/app/help-full-guide") {
				// console.log("location.pathname", location.pathname);
				return <Navigate to={`./app/dashboards/default`} replace />;
			}
		} else if(tokenData.userRole === "ORG_ADMIN" && location.pathname !== "/app/help-full-guide"){
			if (isInSUperadmin > -1) {
				return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
			}
		} else if (tokenData.userRole === "EMPLOYEE") {
			if (isInSUperadmin > -1 && location.pathname !== "/app/help-full-guide") {
				return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
			}
			

			if(employeeNav[sleNavIndex] && employeeNav[sleNavIndex].accessKey && employeeNav[sleNavIndex].accessKey.length){
				if(!tokenData.accessControls || !Object.keys(tokenData.accessControls).length){
					return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
			
				} else if(!tokenData.accessControls[employeeNav[sleNavIndex].accessKey]){
					return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
			
				}

			}
			

		}
	}

	return <Outlet />
}

export default ProtectedRoute