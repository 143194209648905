import fetch from 'auth/FetchInterceptor'

const stageService = {}
// createStage
stageService.createStage = function (data) {
    return fetch({
        url: '/stage/',
        method: 'post',
        data
    })
}

stageService.getStages = function (params) {
    return fetch({
        url: '/stage/',
        method: 'get',
        params
    })
}

stageService.getaStage = function (data) {
    return fetch({
        url: `/stage/${data}`,
        method: 'get'
    })
}

stageService.updateStage = function (data, params) {
    return fetch({
        url: `/stage/${params.id}`,
        method: 'put',
        data
    })
}

stageService.deleteStage = function (params) {
    return fetch({
        url: `/stage/${params.stageId}`,
        method: 'delete',
    })
}



export default stageService;