import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	user: {},
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const validateUser = createAsyncThunk('auth/validateUser',async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.validateUser(data)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { userName, password } = data
	try {
		const response = await AuthService.login({userName, password})
		const token = response.data.token;
		const user = response.data.user;	
		// console.log('respoin', response)
		localStorage.setItem(AUTH_TOKEN, token);
		return {token, user};
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})



export const initiateSSO = createAsyncThunk('auth/initiateSSO',async (data, { rejectWithValue }) => {
	try {
		const {orgId} = data
		const response = await AuthService.initiateSSO(orgId)
		const responseData = response
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const ssoVerify = createAsyncThunk('auth/ssoVerify',async (data, { rejectWithValue }) => {
	const { ssoData, orgId } = data
	try {
		const response = await AuthService.ssoVerify(ssoData, orgId)
		
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return {token};
	} catch (err) {
		return rejectWithValue("unable to authenticate your account, Make sure you are added to system")
	}
})

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password,firstName, lastName } = data
	try {
		const response = await AuthService.register({firstName, lastName, email, password})
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem('orgLogoUrl');
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.resetPassword(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const verifyForgetToken = createAsyncThunk("/auth/forgot-password", async(data, {rejectWithValue}) => {
	try {
		const response = await AuthService.verifyForgetToken(data)
		
		return response;
	}
	catch(err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const submitNewPassword = createAsyncThunk("/auth/forgot-password", async({data, params}, {rejectWithValue}) => {
	try {
		const response = await AuthService.submitNewPassword(data, params)
		
		return response;
	}
	catch(err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload.token
			state.user = action.payload.user
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		hideLoading: (state) => {
			state.loading = false
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.user = action.payload.user
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.user = action.payload.user
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

			.addCase(ssoVerify.pending, (state) => {
				state.loading = true
			})
			.addCase(ssoVerify.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.user = action.payload.user
			})
			.addCase(ssoVerify.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.redirect = '/'
				state.message= "unable to authenticate your account, Make sure you are added to system"
			})

			
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/auth/login'
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	hideLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer