import fetch from 'auth/FetchInterceptor'

const OrgService = {}

OrgService.getOrgs = function (params) {
  return fetch({
    url: '/orgs/',
    method: 'get',
    params
  })
}
OrgService.getOrg = function (data, params) {
  return fetch({
    url: `/orgs/${params.organizationId}`,
    method: 'get'
  })
}
OrgService.updateOrg = function (data, params) {
  return fetch({
    url: `/orgs/${params.organizationId}`,
    method: 'put',
    data
  })
}
OrgService.patchUpdateOrg = function (data , params) {
  return fetch({
    url: `/orgs/${params.organizationId}`,
    method: 'patch',
    data 
  })
}     
OrgService.patchUpdateOrgToken = function (data) {
  return fetch({
    url: `/orgs/${data.id}/add/${data.typeName}/tokens/${data.value}`,
    method: 'patch'
  })
}
OrgService.createOrg = function (data) {
  return fetch({
    url: '/orgs/',
    method: 'post',
    data
  })
}
export default OrgService