import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { m } from 'framer-motion';
import stageService from 'services/StageService';

export const initialState = {
	loading: false,
	error: null,
	message: "",
	showMessage: false,
	stagesData: [],
	stageModelData: {},
	showStageModel: false
}

export const createStage = createAsyncThunk('stage/create', async (data, { rejectWithValue }) => {
	try {
		const response = await stageService.createStage(data);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateStage = createAsyncThunk('stage/update', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await stageService.updateStage(data, params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getStages = createAsyncThunk('stage/getall', async (params, { rejectWithValue }) => {
	try {
		const response = await stageService.getStages(params);

		// console.log(response);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getaStage = createAsyncThunk('stage/getaStage', async (data, { rejectWithValue }) => {
	try {
		const response = await stageService.getaStage(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteStage = createAsyncThunk('stage/delete', async (params, { rejectWithValue }) => {
	try {
		const response = await stageService.deleteStage(params);
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const stageSlice = createSlice({
	name: 'stages',
	initialState,
	reducers: {
		toggleStageModel: (state, action) => {
			const relevantStage = state.stagesData.filter((stage) => stage.milestone === action.payload)
			state.showStageModel = true;
			state.stageModelData = relevantStage
		},
		closeStageModel: (state) => {
			state.showStageModel = false;
			state.stageModelData = {}
		},
		resetState: (state) => {
			state.loading = false;
			state.message = "";
			state.showStageModel = false;
			state.stageModelData = {};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getStages.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getStages.fulfilled, (state, action) => {
				state.loading = false;
				state.stagesData = action.payload.data
			})
			.addCase(getStages.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error;
			})
			.addCase(createStage.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(createStage.fulfilled, (state, action) => {
				state.stagesData.push(action.payload);
				state.loading = false;
			})
			.addCase(createStage.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updateStage.fulfilled, (state, action) => {
				
				// console.log("action.payload after edit stage is ", action);
				let stagesAfterEdit = state.stagesData.map(stage => {
					if(stage.milestone === action.meta.arg.data.milestone) {
						return {
							...action.meta.arg.data
							, stageId: action.meta.arg.params.id
						};
					} else {
						return stage;
					}
				})
				state.stagesData = [...stagesAfterEdit];
				state.loading = false;
			})
			.addCase(updateStage.rejected, (state, action) => {
				state.loading = false;
			})
			.addCase(updateStage.pending, (state, action) => {
				state.loading = true;
			})
	}
});
export const { toggleStageModel, closeStageModel, resetState } = stageSlice.actions

export default stageSlice.reducer

