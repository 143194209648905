import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.validateUser = function (data) {
	return fetch({
		url: `/users/validate/${data.email}`,
		method: 'get',
		data: data
	})
}


AuthService.login = function (data) {
	return fetch({
		url: '/login',
		method: 'post',
		data: data
	})
}

AuthService.initiateSSO= function (orgId) {
	return fetch({
		url: `/sso/azure?orgId=${orgId}`,
		method: 'get'
	})
}



AuthService.ssoVerify = function (data, orgId) {
	return fetch({
		url:`/auth/sso/azure-web/callback?orgId=${orgId}`,
		method: 'post',
		data: data
	})
}
AuthService.register = function (data) {
	return fetch({
		url: '/signup',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.resetPassword = function (data) {
	return fetch ({
		url: '/forgotpassword',
		method: 'post',
		data
	})
}
AuthService.verifyForgetToken = function (token) {
	return fetch ({
		url: `/verifytoken?token=${token}`,
		method: 'get'
	})
}

AuthService.submitNewPassword = function (data, params) {
	return fetch ({
		url: `/forgot/changepassword?token=${params.token}`,
		method: 'put',
		data
	})
}


AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;