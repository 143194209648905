import {
  FileImageOutlined,
  HomeOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  FundProjectionScreenOutlined,
  InsertRowBelowOutlined,
  FormOutlined,
  CodeOutlined,
  EditOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
  AppstoreAddOutlined,
  DatabaseOutlined,
  KeyOutlined,
  CrownOutlined,
  FilePdfOutlined,
  BuildOutlined,
  ContainerOutlined,
  BarChartOutlined,
  AppstoreOutlined,
  RocketOutlined,
  FileTextOutlined,
  SketchOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const superAdminNavs = [
  {
    key: 'dashboards',
    activeRoleId: 'SUPER_ADMIN',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: HomeOutlined,
    // Breadcrumb: true,
    isGroupTitle: true,
    submenu: [
      {
        key: 'dashboards-default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: 'sidenav.dashboard.default',
        icon: HomeOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      {
        key: 'orgs-list',
        path: `${APP_PREFIX_PATH}/orgs/orgs-list`,
        title: 'sidenav.orgs.orgs-list',
        icon: InsertRowBelowOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      {
        key: 'skill&categories',
        path: `${APP_PREFIX_PATH}/skill-categories`,
        title: 'sidenav.skill-categories',
        icon: EditOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      // {
      //   key: 'extraSkill&categories',
      //   path: `${APP_PREFIX_PATH}/skill-categories/?sfiaType=2`,
      //   title: 'sidenav.extra-skill-categories',
      //   icon: FormOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // },
      {
        key: 'helpFullGuide',
        path: `${APP_PREFIX_PATH}/help-full-guide`,
        title: 'sidenav.helpFullGuide',
        icon: UserOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      // {
      //   key: 'levels',
      //   path: `${APP_PREFIX_PATH}/levels`,
      //   title: 'Level',
      //   icon: BarChartOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // },
      {
        key: 'stages',
        path: `${APP_PREFIX_PATH}/stages`,
        title: 'Stages',
        icon: RocketOutlined,
        // Breadcrumb: true,
        submenu: []
      }
      // {
      //   key: 'questions',
      //   path: `${APP_PREFIX_PATH}/stages/:stageId/questions`,
      //   title: 'Questions',
      //   icon: QuestionOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // }
      // {
      //   key: 'users-list',
      //   path: `${APP_PREFIX_PATH}/users/users-list`,
      //   title: 'sidenav.users.users-list',
      //   icon: UserOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // }
    ]
  }
]
const docsNavTree = [{
  key: 'docs',
  path: `${APP_PREFIX_PATH}/docs`,
  title: 'sidenav.docs',
  icon: BookOutlined,
  // Breadcrumb: true,
  isGroupTitle: true,
  submenu: [
    {
      key: 'docs-documentation',
      path: `${APP_PREFIX_PATH}/docs/documentation`,
      title: 'sidenav.docs.documentation',
      icon: FileUnknownOutlined,
      // Breadcrumb: true,
      submenu: []
    },
    {
      key: 'docs-changelog',
      path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
      title: 'sidenav.docs.changelog',
      icon: ProfileOutlined,
      // Breadcrumb: true,
      submenu: []
    }
  ]
}]

const orgAdminNavs = [
  {
    key: 'Org',
    activeRoleId: "ORG_ADMIN",
    path: `${APP_PREFIX_PATH}/org`,
    title: 'ORGANISATION',
    icon: BookOutlined,
    // Breadcrumb: true,
    isGroupTitle: true,
    submenu: [
      {
        key: 'my-dashboard',
        path: `${APP_PREFIX_PATH}/org/my-dashboard`,
        title: "Dashboard",
        icon: AppstoreOutlined,
        // Breadcrumb: true,
        accessKey: '',
        submenu: []
      },
      // {
      //   key: 'token-management',
      //   path: `${APP_PREFIX_PATH}/org/token-management`,
      //   title: "Token Management",
      //   icon: KeyOutlined,
      //   // Breadcrumb: true,
      //   accessKey: 'tokenManagement',
      //   submenu: []
      // },
      {
        key: 'assessment',
        path: `${APP_PREFIX_PATH}/org/assessment`,
        title: "Assessment",
        icon: KeyOutlined,
        // Breadcrumb: true,
        accessKey: 'hasAssessment',
        submenu: []
      },
      {
        key: 'accessControl',
        path: `${APP_PREFIX_PATH}/access-control`,
        title: 'sidenav.accessControl',
        icon: FormOutlined,
        accessKey: 'hasAccessControl',
        // Breadcrumb: true,
        submenu: []
      },

      {
        key: 'roles-admin',
        path: `${APP_PREFIX_PATH}/org/roles`,
        title: 'administration.roles-admin',
        accessKey: 'hasRoles',
        icon: ContainerOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      {
        key: 'department-admin',
        path: `${APP_PREFIX_PATH}/org/departments`,
        title: 'Departments',
        accessKey: 'hasDepartment',
        icon: DatabaseOutlined,
        // Breadcrumb: true,
        submenu: []
      },
      {
        key: 'user',
        title: 'sidenav.user',
        path: `${APP_PREFIX_PATH}/employee`,
        icon: UserOutlined,
        // Breadcrumb: true,
        accessKey: 'hasUsers',
        submenu: [
          // {
          //   key: 'administrators',
          //   path: `${APP_PREFIX_PATH}/administrators`,
          //   title: 'sidenav.administrators',
          //   icon: UsergroupAddOutlined,
          //   // Breadcrumb: true,
          //   submenu: []
          // },
          // {
          //   key: 'Verifiers/Managers',
          //   path: `${APP_PREFIX_PATH}/Verifiers-Managers`,
          //   title: 'sidenav.Verifiers/Managers',
          //   icon: UserOutlined,
          //   // Breadcrumb: true,
          //   submenu: []
          // }, 
          // {
          //   key: 'Employee',
          //   path: `${APP_PREFIX_PATH}/employee`,
          //   title: 'sidenav.employee',
          //   icon: UserOutlined,
          //   // Breadcrumb: true,
          //   submenu: []
          // }

        ]
      },

      {
        key: 'jobRoleBuilder',
        path: `${APP_PREFIX_PATH}/job-role-builder`,
        title: 'sidenav.job-role-builder',
        accessKey: 'hasJobRole',
        icon: BuildOutlined,
        // Breadcrumb: true,
        submenu: []
      },

      // {
      //   key: 'reports',
      //   path: `${APP_PREFIX_PATH}/reports`,
      //   title: 'Reports',
      //   accessKey: 'hasAssessment',
      //   icon: FileTextOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // },

      {
        key: 'reports',
        title: 'Reports',
        path: `${APP_PREFIX_PATH}/reports`,
        icon: FileTextOutlined,
        // Breadcrumb: true,
        accessKey: 'hasAssessment',
        submenu: [
          {
            key: 'assessmentReports',
            path: `${APP_PREFIX_PATH}/reports/assessment-reports`,
            title: 'Assessment Report',
            icon: KeyOutlined,
            // Breadcrumb: true,
            submenu: []
          },
          {
            key: 'skillReport',
            path: `${APP_PREFIX_PATH}/reports/skill-reports`,
            title: 'Skill Report',
            icon: SketchOutlined,
            // Breadcrumb: true,
            submenu: []
          },
          {
            key: 'jb-analysis',
            path: `${APP_PREFIX_PATH}/reports/jb-analysis`,
            title: 'Job Role Report',
            icon: BuildOutlined,
            // Breadcrumb: true,
            submenu: []
          },
          {
            key: 'code-performance',
            path: `${APP_PREFIX_PATH}/reports/code-performance`,
            title: 'Code Performance',
            icon: CodeOutlined,
            // Breadcrumb: true,
            submenu: []
          },
          {
            key: 'competency-report',
            path: `${APP_PREFIX_PATH}/reports/competency-report`,
            title: 'Competency Report',
            icon: FundProjectionScreenOutlined,
            // Breadcrumb: true,
            submenu: []
          }
          // {
          //   key: 'jobRoleData',
          //   path: `${APP_PREFIX_PATH}/reports/job-role-data`,
          //   title: 'Job-Role-Data',
          //   icon: UserOutlined,
          //   // Breadcrumb: true,
          //   submenu: []
          // }, 


        ]
      },

      // {
      //   key: 'org-course-logs',
      //   path: `${APP_PREFIX_PATH}/org/course-logs`,
      //   title: 'administration.org-course-logs',
      //   accessKey: 'createCareerPathway',
      //   icon: BookOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // },

      {
        key: 'org-skill-logs',
        path: `${APP_PREFIX_PATH}/org/skill-logs`,
        title: 'administration.org-skill-logs',
        accessKey: 'hasSkillLogs',
        icon: CrownOutlined,
        // Breadcrumb: true,
        submenu: []
      },


      // {
      //   key: 'knowledge-bank',
      //   path: `${APP_PREFIX_PATH}/org/knowledge`,
      //   title: 'Knowledge Bank',
      //   icon: CopyOutlined,
      //   // Breadcrumb: true,
      //   submenu: []
      // },
      {
        key: 'Development-room',
        path: `${APP_PREFIX_PATH}/developmentroom`,
        title: 'Development-room',
        icon: AppstoreAddOutlined,
        accessKey: 'hasAdminDevelopmentRoom',
        // Breadcrumb: true,
        submenu: []
      },

      {
        key: 'careerpathway',
        path: `${APP_PREFIX_PATH}/careerpathways`,
        title: 'Org Career Pathway',
        icon: CopyOutlined,
        // accessKey: 'hasCarrierPathway',
        // Breadcrumb: true,
        submenu: []
      },



      // {
      //   key: 'assesmentEditReport',
      //   path: `${APP_PREFIX_PATH}/assesment-edit-report/:assessmentId`,
      //   // title: 'sidenav.assesment-edit-report',
      //   accessKey: 'createJobRole',
      //   icon: BuildOutlined,
      //   // // Breadcrumb: true,
      //   submenu: []
      // },
      {
        key: 'helpFullGuide',
        path: `${APP_PREFIX_PATH}/help-full-guide`,
        title: 'sidenav.helpFullGuide',
        icon: FilePdfOutlined,
        accessKey: "hasGuides",
        // Breadcrumb: true,
        submenu: []
      },
      {
        key: 'org-logo',
        path: `${APP_PREFIX_PATH}/org/logo`,
        title: 'administration.logo',
        accessKey: 'hasOrg',
        icon: FileImageOutlined,
        // Breadcrumb: true,
        submenu: []
      },

      {
        key: 'org-help',
        path: `${APP_PREFIX_PATH}/org/help`,
        title: 'administration.help',
        icon: QuestionCircleOutlined,
        // Breadcrumb: true,
        submenu: []
      },

      // {
      //   key: 'extra-pages-setting',
      //   path: `${APP_PREFIX_PATH}/views/app-views/users/setting`,
      //   title: 'sidenav.pages.setting',
      //   icon: '',
      //   // Breadcrumb: true,
      //   submenu: []
      // }
    ]
  }]

const navigationConfig = [
  ...superAdminNavs,
  ...orgAdminNavs
  // ...docsNavTree
]


export default navigationConfig;
