import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import OrgService from 'services/OrgService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	listOrg: [],
	selectedOrg: {},
	orgLogoUrl: '',
	orgTokenDetails: {}
}

export const getOrgs = createAsyncThunk('org/list', async (data, { rejectWithValue }) => {
	try {
		const response = await OrgService.getOrgs(data)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOrg = createAsyncThunk('org/item', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await OrgService.getOrg(data, params)
		const responseData = response.data
		// console.log('respoin', responseData)
		return responseData;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createOrg = createAsyncThunk('orgs/orgs-list', async (data, { rejectWithValue }) => {
	try {

		const response = await OrgService.createOrg(data)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOrg = createAsyncThunk('orgs/orgs-list', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await OrgService.updateOrg(data, params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const patchUpdateOrg = createAsyncThunk('orgs/orgs-list', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await OrgService.patchUpdateOrg(data, params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const patchUpdateOrgToken = createAsyncThunk('/orgs/add/update/tokens', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await OrgService.patchUpdateOrgToken(data)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const orgSlice = createSlice({
	name: 'org',
	initialState,
	reducers: {
		listOrg: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		}
	},
	extraReducers: {

		[getOrgs.pending]: (state) => {
			state.loading = true;
		},
		[getOrgs.fulfilled]: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.orgs = action.payload
		},
		[getOrgs.rejected]: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		[getOrg.fulfilled]: (state, action) => {
			state.selectedOrg  = action.payload
			state.orgLogoUrl = action.payload.logo
			state.orgTokenDetails = action.payload.token
		}

		// builder
		// 	.addCase(getOrgs.pending, (state) => {
		// 		state.loading = true
		// 	})
		// 	.addCase(getOrgs.fulfilled, (state, action) => {
		// 		state.loading = false
		// 		state.redirect = '/'
		// 		state.orgs = action.payload
		// 	})
		// 	.addCase(getOrgs.rejected, (state, action) => {
		// 		state.message = action.payload
		// 		state.showMessage = true
		// 		state.loading = false
		// 	})


	},
})

export const {
	listOrg,
} = orgSlice.actions

export default orgSlice.reducer