import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'sso',
        path: `${AUTH_PREFIX_PATH}/sso/azure/callback`,
        component: React.lazy(() => import('views/auth-views/authentication/sso'))
    },
    {
        key: 'initiatesso',
        path: `${AUTH_PREFIX_PATH}/:orgId/sso/azure/`,
        component: React.lazy(() => import('views/auth-views/authentication/sso/ssoNavigate'))
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default/index')),
    },
    {
        key: 'users.user-list',
        path: `${APP_PREFIX_PATH}/users/users-list`,
        component: React.lazy(() => import('views/app-views/users/user-list')),
    },
    {
        key: 'orgs.org-list',
        path: `${APP_PREFIX_PATH}/orgs/orgs-list`,
        component: React.lazy(() => import('views/app-views/orgs/org-list')),
    },
    {
        key: 'skill&categories',
        path: `${APP_PREFIX_PATH}/skill-categories/`,
        component: React.lazy(() => import('views/app-views/skill-categories')),
    },
    {
        key: 'helpFullGuide',
        path: `${APP_PREFIX_PATH}/help-full-guide/`,
        component: React.lazy(() => import('views/app-views/help-full-guide')),
    },
    {
        key: 'administrators',
        path: `${APP_PREFIX_PATH}/administrators/`,
        component: React.lazy(() => import('views/app-views/administrators')),
    },
    {
        key: 'Verifiers/Managers',
        path: `${APP_PREFIX_PATH}/Verifiers-Managers/`,
        component: React.lazy(() => import('views/app-views/org/verifiersReport')),
    },
    {
        key: 'employee',
        path: `${APP_PREFIX_PATH}/employee/`,
        component: React.lazy(() => import('views/app-views/org/employee')),
    },
    {
        key: 'jobRoleBuilder',
        path: `${APP_PREFIX_PATH}/job-role-builder/`,
        component: React.lazy(() => import('views/app-views/org/jobRoleBuilder')),
    },
    {
        key: 'assessmentReports',
        path: `${APP_PREFIX_PATH}/reports/`,
        component: React.lazy(() => import('views/app-views/org/assessment/index.js')),
    },
    {
        key: 'assessmentReports',
        path: `${APP_PREFIX_PATH}/reports/assessment-reports/`,
        component: React.lazy(() => import('views/app-views/org/reporting/assessment-report/index.js')),
        // src/views/app-views/org/reporting/assessment-report/index.js
    },
    {
        key: 'assessmentReports',
        path: `${APP_PREFIX_PATH}/reports/user-analysis/:userId`,
        component: React.lazy(() => import('views/app-views/org/reporting/assessment-report/user-role-skill-report/index.js')),
        // src/views/app-views/org/reporting/assessment-report/index.js
    },
    {
        key: 'jobRoleReports',
        path: `${APP_PREFIX_PATH}/reports/jb-analysis`,
        component: React.lazy(() => import('views/app-views/org/reporting/job-roles-report/')),
        // src/views/app-views/org/reporting/assessment-report/index.js
    },
    {
        key: 'jobRoleReports',
        path: `${APP_PREFIX_PATH}/reports/jobrole-analysis/:jobRoleId`,
        component: React.lazy(() => import('views/app-views/org/reporting/job-roles-report/job-role/index.js')),
        // src/views/app-views/org/reporting/assessment-report/index.js
    },
    {
        key: 'skillReports',
        path: `${APP_PREFIX_PATH}/reports/skill-reports`,
        component: React.lazy(() => import('views/app-views/org/reporting/skill-report/index.js')),
        // src/views/app-views/org/reporting/assessment-report/index.js
    },

    {
        key: 'assesmentEditReport',
        path: `${APP_PREFIX_PATH}/report/:assessmentId`,
        component: React.lazy(() => import('views/app-views/org/my-dashboard/assesmentEditReport'))
    },
    {
        key: 'assesmentQuSteps',
        path: `${APP_PREFIX_PATH}/assessment/:id`,
        component: React.lazy(() => import('views/app-views/org/my-dashboard/qu-steps'))
    },
    {
        key: 'jobRoleBuilder',
        path: `${APP_PREFIX_PATH}/job-role-builder/add`,
        component: React.lazy(() => import('views/app-views/org/jobRoleBuilder/addEditTabs')),
    },
    {
        key: 'selfAssessment',
        path: `${APP_PREFIX_PATH}/self-assessment/:assessmentId`,
        component: React.lazy(() => import('views/app-views/org/self-assessment/addEditTabs')),
    },
    {
        key: 'jobRoleBuilder',
        path: `${APP_PREFIX_PATH}/job-role-builder/edit/:jobRoleId`,
        component: React.lazy(() => import('views/app-views/org/jobRoleBuilder/addEditTabs')),
    },
    
    {
        key: 'careerpathway',
        path: `${APP_PREFIX_PATH}/careerpathways`,
        component: React.lazy(() => import('views/app-views/career-path-ways')),
    },
    {
        key: 'careerpathway',
        path: `${APP_PREFIX_PATH}/careerpathways/:departMeentId`,
        component: React.lazy(() => import('views/app-views/career-path-ways/carerrPathByDepId')),
    },
    {
        key: 'jbcompetency',
        path: `${APP_PREFIX_PATH}/jbcompetency/view/:jobRoleId`,
        component: React.lazy(() => import('views/app-views/career-path-ways/viewDescriptionRole')),
    },
    {
        key: 'developmentroom',
        path: `${APP_PREFIX_PATH}/developmentroom/`,
        component: React.lazy(() => import('views/app-views/org/development-room')),
    },
    {
        key: 'employeedevelopmentroom',
        path: `${APP_PREFIX_PATH}/dev-area/:userId/:assessmentId`,
        component: React.lazy(() => import('views/app-views/org/development-room/developmentRoom.js')),
    },

    {
        key: 'developmentroom',
        path: `${APP_PREFIX_PATH}/associated-courses/:assessmentId/:skillCode/:userId`,
        component: React.lazy(() => import('views/app-views/org/development-room/associateCourse.js')),
    },
    
    {
        key: 'administration.roles-admin',
        path: `${APP_PREFIX_PATH}/org/roles`,
        component: React.lazy(() => import('views/app-views/org/roles')),
    },
    {
        key: 'administration.roles-admin',
        path: `${APP_PREFIX_PATH}/org/roles`,
        component: React.lazy(() => import('views/app-views/org/roles')),
    },

    {
        key: 'department-admin',
        path: `${APP_PREFIX_PATH}/org/departments`,
        component: React.lazy(() => import('views/app-views/org/departments')),
    },
    {
        key: 'org-logo',
        path: `${APP_PREFIX_PATH}/org/logo`,
        component: React.lazy(() => import('views/app-views/org/org-logo')),
    },
    {
        key: 'org-skill-logs',
        path: `${APP_PREFIX_PATH}/org/skill-logs`,
        component: React.lazy(() => import('views/app-views/org/skill-logs')),
    },
    {
        key: 'employee-skill-logs',
        path: `${APP_PREFIX_PATH}/org/employee-skill-logs/:uid`,
        component: React.lazy(() => import('views/app-views/org/skill-logs/skillLogs')),
    },
    {
        key: 'accessControl',
        path: `${APP_PREFIX_PATH}/access-control`,
        component: React.lazy(() => import('views/app-views/org/access-control')),
    },
    {
        key: 'org-help',
        path: `${APP_PREFIX_PATH}/org/help`,
        component: React.lazy(() => import('views/app-views/org/help')),
    },

    {
        key: 'knowledge-bank',
        path: `${APP_PREFIX_PATH}/org/knowledge`,
        component: React.lazy(() => import('views/app-views/org/knowledge/index.js')),
    },
    {
        key: 'knowledge-bank-emp',
        path: `${APP_PREFIX_PATH}/org/knowledge/:userId`,
        component: React.lazy(() => import('views/app-views/org/knowledge/employeeKnowledge.js')),
    },
    
    // {
    //     key: 'token-management',
    //     path: `${APP_PREFIX_PATH}/org/token-management`,
    //     component: React.lazy(() => import('views/app-views/org/tokenManagement/index.js')),
        
    // },
    {
        key: 'assessment',
        path: `${APP_PREFIX_PATH}/org/assessment`,
        component: React.lazy(() => import('views/app-views/org/assessment/index.js'))
    },
    {
        key: 'my-dashboard',
        path: `${APP_PREFIX_PATH}/org/my-dashboard`,
        component: React.lazy(() => import('views/app-views/org/my-dashboard/index.js')),
    },
    {
        key: 'levels',
        path: `${APP_PREFIX_PATH}/levels`,
        component: React.lazy(() => import('views/app-views/levels')),
    },
    {
        key: 'stages',
        path: `${APP_PREFIX_PATH}/stages`,
        component: React.lazy(() => import('views/app-views/stages')),
    },
    {
        key: 'questions',
        path: `${APP_PREFIX_PATH}/stages/:stageId/questions/:sfia`,
        component: React.lazy(() => import('views/app-views/stages/questions')),
    },

    // {
    //     key: 'pages.setting',
    //     path: `${APP_PREFIX_PATH}/views/app-views/users/setting/*`,
    //     component: React.lazy(() => import('views/app-views/users/setting')),
    // },
    {
        key: 'code-performance',
        path: `${APP_PREFIX_PATH}/reports/code-performance`,
        component: React.lazy(() => import('views/app-views/org/reporting/code-performance/index.js')),
    },
    {
        key: 'competency-report',
        path: `${APP_PREFIX_PATH}/reports/competency-report`,
        component: React.lazy(() => import('views/app-views/org/reporting/competency-report/index.js')),
    },

    {
        key: 'competency-report-level',
        path: `${APP_PREFIX_PATH}/reports/competency-report/:level`,
        component: React.lazy(() => import('views/app-views/org/reporting/competency-report/skill-competency/codeLevel.js')),

    },

   
]