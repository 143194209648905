import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { m } from 'framer-motion';
import questionService from 'services/QuestionService';

export const initialState = {
	loading: false,
	error: null,
	message: "",
	showMessage: false
}

export const createQuestion = createAsyncThunk('question/create', async (data, { rejectWithValue }) => {
	try {
		const response = await questionService.createQuestion(data);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const addBulkQuestion = createAsyncThunk('question/csv', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await questionService.addBulkQuestion(data, params);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateQuestion = createAsyncThunk('question/update', async ({ data, params }, { rejectWithValue }) => {
	try {
		const response = await questionService.updateQuestion(data, params)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getQuestions = createAsyncThunk('question/getall', async (params, { rejectWithValue }) => {
	try {
		const response = await questionService.getQuestions(params);
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getaQuestion = createAsyncThunk('question/getaQuestion', async (data, { rejectWithValue }) => {
	try {
		const response = await questionService.getaQuestion(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteQuestion = createAsyncThunk('question/delete', async (params, { rejectWithValue }) => {
	try {
		const response = await questionService.deleteQuestion(params);
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const questionSlice = createSlice({
	name: 'questions',
	initialState,
	reducers: {
		resetState: (state) => {
			state.loading = false;
			state.message = "";
			state.error = null;
			state.showMessage = false;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getQuestions.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getQuestions.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getQuestions.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error;
			})
			.addCase(createQuestion.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(createQuestion.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(createQuestion.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updateQuestion.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(updateQuestion.rejected, (state, action) => {
				state.loading = false;
			})
			.addCase(updateQuestion.pending, (state, action) => {
				state.loading = true;
			})
	}
});
export const { resetState } = questionSlice.actions;

export default questionSlice.reducer;

