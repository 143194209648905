import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { notification } from "antd";

const unauthorizedCode = [401, 403];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});



// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
      const finaljWT = `Bearer ${jwtToken}`;
      config.headers[TOKEN_PAYLOAD_KEY] = finaljWT;
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
	const contentType = response.headers['content-type'];
	if ( contentType === 'application/pdf') {
		const contentDisposition = response.headers["content-disposition"];
		return {
			blob: response.data,
			contentDisposition
		}
	}
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };
    if (error.response) {
      if (unauthorizedCode.includes(error.response.status)) {
        notificationParam.message = "Authentication Fail";
        notificationParam.description = "Please login again";
        localStorage.removeItem(AUTH_TOKEN);
        store.dispatch(signOutSuccess());
      }

      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
      }
      if (error.response.status === 400) {
        notificationParam.message = error.response.data.message;
      }
      if (error.response.status === 409) {
        notificationParam.message = error.response.data.message;
      }

      if (error.response.status === 500) {
        notificationParam.message = "Internal Server Error";
      }

      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
      }
    } else {
      notificationParam.message = "Network Error";
      notificationParam.description = "Internal Server Error";
    }
    notification.error(notificationParam);
    return Promise.reject(error);
  }
);

export default service;
